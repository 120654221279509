<template>
  <WeCard class="border-radius-9">
    <!-- Title & Refresh -->
    <div class="row align-items-center mb-3">
      <div class="col">
        <h5 class="mb-0">Çok Satan Kategoriler</h5>
      </div>
      <div class="col-auto">
        <div class="btn-group">
          <span class="btn btn-light btn-sm" v-on:click="onRefresh">
            <i class="fas fa-sync"></i>
          </span>
        </div>
      </div>
    </div>
    <!-- ./Title & Refresh -->

    <hr />

    <!-- Loader -->
    <div class="position-relative" style="min-height: 350px" v-if="loading">
      <WeLoading />
    </div>
    <!-- ./Loader -->

    <!-- Data -->
    <ul class="list-group" v-else-if="data.length">
      <li
        class="list-group-item border-0 hover-shadow mb-1 border-radius-9"
        v-for="(category, index) in data"
        v-bind:key="category.id"
        v-bind:class="{ 'bg-light': index % 2 == 1 }"
      >
        <div class="row align-items-center">
          <div class="col-auto text-center" style="min-width: 50px">
            <h6 class="mb-0 text-muted">{{ 1 * index + 1 }}</h6>
          </div>
          <div class="col">
            <h6 class="mb-0" v-bind:class="{ 'text-muted': !category.name }">
              {{ category.name || "Belirtilmedi" }}
            </h6>
          </div>
          <div class="col-auto">
            <span class="font-weight-bold">{{ category.sale_quantity }} </span>
            Adet
          </div>
        </div>
      </li>
    </ul>
    <WeCard class="alert-primary" v-else>
      <i class="fas fa-info-circle"></i> <span v-html="getInfo"></span>
    </WeCard>
    <!-- ./Data -->
  </WeCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CategoryProduct",
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    ...mapActions("widget", ["refreshReport"]),
    onRefresh() {
      this.loading = true;
      this.refreshReport({
        name: "bestSellerCategories",
        params: {
          period: this.period,
        },
        onSuccess: (result) => {
          let items = result.data.items || [];

          if (items && items.length) {
            items.map((item) => {
              item.sale_quantity = new Intl.NumberFormat("tr-TR").format(
                item.sale_quantity
              );
              return item;
            });
          }

          this.data = items;
          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
    getInfo() {
      if (!this.data.length) {
        let period = this.report.periodFilters.find(
          (item) => item.id == this.report.period
        );
        if (period) {
          return `<b>${period.name}</b> için listede kategori bulunmamaktadır.`;
        }
      }
    },
  },
  mounted() {
    this.onRefresh();
  },
  watch: {
    "report.period": function(newVal) {
      if (newVal && newVal !== "custom_date") {
        this.onRefresh(true);
      }
    },
    "report.periodDate": function(newVal) {
      if (newVal && newVal.start && newVal.end) {
        this.onRefresh(true);
      }
    },
  },
};
</script>
